<template>
  <div class="box has-backgorund-gray">
    <div class="button is-primary" @click="generate">Generate Fractal</div>
      
      <div class=" column -is-flex p-4" v-if="generated">
        <div class="button is-primary" @click="up">
          <unicon name="arrow-up" fill="white"></unicon>
        </div>
        <div class="button is-primary" @click="down">
          <unicon name="arrow-down" fill="white"></unicon>
        </div>
        <div class="button is-primary" @click="left">
          <unicon name="arrow-left" fill="white"></unicon>
        </div>
        <div class="button is-primary" @click="right">
          <unicon name="arrow-right" fill="white"></unicon>
        </div>
        <div class="button is-primary" @click="zoomIn">
          <unicon name="plus" fill="white"></unicon>
        </div>
        <div class="button is-primary" @click="zoomOut">
          <unicon name="minus" fill="white"></unicon>
        </div>
        <div class="container mt-2">
          <div ref="download-button" class="button is-success" @click="download">Download Twitter Banner</div>
        </div>
  
      </div>
  </div>
</template>
<script>
import { UTILS} from "../common"
import Mandelbrot from "../models/mandelbrot"
import {ethers} from "ethers"
import { SETTINGS } from '@/constants'
import {  PALETTE_ARRAY } from '@/common/palettes'
export default {
    name: "TheFractal",
    inject: ['provider'],
    data() {
      return {
        palette: [],
        fractal: {},
        xmin: 0,
        ymin: 0,
        scale: 0,
        iterations: 0,
        bailout: 0,
        pixels: 0,
        delta: 0,
        generated: false
      }
    },
    methods: {
      download() {
        console.log(SETTINGS);
        const canvas = document.createElement('canvas')
        canvas.width = 1500
        canvas.height = 500
        const ratio = canvas.height/SETTINGS.height
        const ctx = canvas.getContext('2d')
        ctx.scale(ratio, ratio)
        const fractal = new Mandelbrot( this.xmin, this.ymin, this.scale, this.palette, this.iterations, this.bailout, this.pixels, canvas.width, canvas.height)
        fractal.drawMandelSet(ctx)
        const img = canvas.toDataURL('image/png') 
        let a = document.createElement('a');
        // Set the link to the image so that when clicked, the image begins downloading
        a.href = img
        // Specify the image filename
        a.download = 'canvas-download.png';
        // Click on the link to set off download
        a.click();
      },
      down() {
        console.log("down")
        // console.log(this.fractal.ymin);
        this.ymin += this.pixels*2/(this.scale)
        this.render()
      },
      up() {
        console.log("up")
        // console.log(this.fractal.ymin);
        this.ymin -= this.pixels/(this.scale)
        this.render()
      },
      left() {
        console.log("left")
        // console.log(this.fractal.ymin);
        this.xmin -= this.pixels/(this.scale)
        this.render()
      },
      right() {
        console.log("right")
        // console.log(this.fractal.ymin);
        this.xmin += this.pixels/(this.scale)
        this.render()
      },
      zoomIn() {
        this.scale = Math.floor(this.scale * 1.2)
        this.render()
      },
      zoomOut() {
        this.scale = Math.floor(this.scale * 0.6)
        this.render()
      },
      generate() {
        const palette_list = PALETTE_ARRAY()
        const index = UTILS.getRandomIntRange(0, palette_list.length-1)
        console.log(index);
        const palette = palette_list[index]
        this.palette = palette()
        this.scale=UTILS.getRandomIntRange(100,5000);
        this.xmin=-UTILS.getRadomValueRange(0.55, 0.7)-1/this.scale;
        this.ymin=-UTILS.getRadomValueRange(0.58, 0.67)-1/this.scale;
        this.iterations = 255;
        this.delta = UTILS.getRandomIntRange(1,6)
        this.bailout = 2.5;
        this.pixels = this.delta;
        
        this.render()
        this.generated = true;
      },
      render() {
        if (!this.provider.context) return;
        const ctx = this.provider.context;
        
        const metadata = {
          x: this.xmin, //uint256
          y: this.ymin, //uint256
          scale: this.scale, //uint256
          palette: this.palette, //array 
          iterations: this.iterations,
          bailout: this.bailout,
          pixels: this.pixels
        }
        console.log(metadata);
        const text_metdata = JSON.stringify(metadata)
        const size = new TextEncoder().encode(text_metdata.toString()).length
        console.log("Wight(kb): ", size/1024);
        console.log(text_metdata.toString(), text_metdata, size)
        const data = ethers.utils.defaultAbiCoder.encode(["string"],[text_metdata.toString()])
        console.log(data);
        const p = ethers.utils.defaultAbiCoder.decode(["string"], data)
        const decodedPalette = JSON.parse(p) 
        console.log(decodedPalette);
        let fractal = new Mandelbrot(this.xmin, this.ymin, this.scale, decodedPalette.palette, this.iterations, this.bailout, this.pixels);
        
        fractal.drawMandelSet(ctx)
        this.fractal = fractal
        
        // for(let i=0; i<256; i++) {
        //   setInterval( () => {
        //   ctx.rect(i, j, 10, 10);
        //   ctx.fillStyle = this.palette[i]
        //   ctx.fill();
        //   }, 100)  
        // }  
      }
    },
    mounted() {
     
      

    }
    // eslint-disable-next-line vue/require-render-return
    
}
</script>

<style>

</style>