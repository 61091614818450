<template>
  <div class="title">FRACTAL ENTROPY</div>
      
      

  <fractal-canvas class="p-3" >
    <the-fractal ref="fractal"></the-fractal>
  </fractal-canvas>
 
</template>

<script>
import TheFractal from './components/TheFractal.vue'
import FractalCanvas from './components/FractalCanvas.vue'

export default {
  name: 'App',
  components: {
    FractalCanvas,
    TheFractal
  },
  methods: {
     
    down() {
      this.$refs['fractal'].down()
    },
    up() {
      this.$refs['fractal'].up()
    },
    generate() {
      this.$refs['fractal'].generate()
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
