import { UTILS } from "@/common/utils";
import { SETTINGS } from "@/constants";
export default class Mandelbrot {
  xt;
  zx; 
  zy;
  cx;
  cy;
  xmin;
  ymin;
  width;
  height;
  constructor(
    init_x, init_y, scale, palette, iterations, bailout, pixels, w=SETTINGS.width, h=SETTINGS.height, 
  ) {
      //fractal defining variables
      console.log("CONSTRUCTOR");
      this.xmin = init_x;
      this.ymin = init_y;
      this.scale = scale;
      this.palette = palette;
      this.iterations = iterations;
      this.bailout = bailout;
      this.pixels = pixels;
      this.index = 0;
      this.fractalMap = new Map();
      this.width = w
      this.height = h
      
      console.log(this.width , this.height, w, h)
  }
    
  calculateMandelSet() {
    this.index = 0;
    var i;
    for( var scrX = 0; scrX < this.width; scrX++ )
    {   
      for( var scrY = 0; scrY < this.height; scrY++ )
      {
        i=0;
        this.cx=this.xmin+scrX/this.scale;
        this.cy=this.ymin+scrY/this.scale;
        this.zx=0;
        this.zy=0;                        
        do {
          this.xt=this.zx*this.zy;
          this.zx=this.zx*this.zx-this.zy*this.zy+this.cx; //calculate for next iteration
          this.zy=2*this.xt+this.cy; // complex number 
          i++;
          this.fractalMap.set(this.index, [scrX, scrY, i]);
          this.index++;
        }
        while(i < this.iterations &&(this.zx*this.zx+this.zy*this.zy)< this.bailout);
      }     
    }
  }
  move( ctx, direction, delta ) {
    console.log(direction, " ", delta);
    // if( 
    //   direction!="UP" ||
    //   direction!="DOWN" ||
    //   direction!="LEFT" ||
    //   direction!="RIGHT"
    // ) {
    //   throw new Error("Direction non valid!")
    // }

    switch(direction) {
      case "UP": {
        this.ymin = this.ymin-delta
      } break;
      case "DOWN": {
        this.ymin = this.ymin+delta;
      } break;
    }

  }
  drawMandelSet(context) {  
    context.clearRect(0,0,this.width, this.height)
    console.log("drawing...");  
    var x,y;
    const offset = UTILS.getRadomValueRange(1,1)
    for(x=0;x<this.width;x++)
    {
      for(y=0;y<this.height;y++)
      {
        var i=0;
        this.cx=this.xmin+x/this.scale;
        this.cy=this.ymin+y/this.scale;
        this.zx=0;
        this.zy=0;                        

        do
        {
          const rnd = 0 //UTILS.getRadomValueRange(0.001,0.02)
          this.xt=(rnd+this.zx)*(rnd+this.zy);
          this.zx=this.zx*this.zx-this.zy*this.zy+this.cx*offset; //calculate for next iteration
          this.zy=2*this.xt+this.cy; // complex number 
          i++;
        }
        while(i < this.iterations &&(this.zx*this.zx+this.zy*this.zy)<this.bailout);
        
        context.beginPath();
        context.rect(this.pixels*x, this.pixels*y, this.pixels, this.pixels);
        context.fillStyle = this.palette[i];
        context.fill();
      }
      // window.requestAnimationFrame(this.drawMandelSet)
    }
    console.log("finished!");
  }
}
