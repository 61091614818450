import { UTILS } from "./utils";
let r,g,b;
export const PALETTE = {
    
    blue(){
        let array = [];
        for(let x=0;x<256;x++) // the loop that creates the pallette
        {
            
            {     
            if(x<15) // colors 0-84
            r=0;
            g=0;
            b=5+x;
        }
        if(x>14 < 30) // colors 0-84
        {       
            r=0;
            g=0;
            b=0;
        }
        if(x>40 < 45) // colors 0-84
        {       
            r=0;
            g=0;
            b=2*x;
        }
        if(x>44 < 60) // colors 0-84
        {       
            r=0;
            g=0;
            b=70+x;
        }
        if(x>59&&x<85) // colors 85-170
        { 
            r=0;
            g=x-15;
            b=120+x;
        }
        if(x>84&&x<125) // colors 85-170
        {
            r=0;
            g=0;
            b=170+x;
        }
        if(x>124&&x<171) // colors 85-170
        {
            r=0;
            g=0;
            b=0;
        }
        if(x>170) // colors 170-255
        {
            r=0;
            g=0;
            b=Math.floor(x/4);
        }

        r=r.toString(16); // conversion to hex
        g=g.toString(16);
        b=b.toString(16);        

        if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
        if (g.length==1) g="0"+g;
        if (b.length==1) b="0"+b;

        array[x]="#"+r+g+b; // final hex string
        }   
        return array;
    },
    redIncremental() {
        let array = [];
        for(let x=0; x<256; x++) {
            // let one = UTILS.getRandomIntRange(1, 9)
            let two = UTILS.getRandomIntRange(170, 250)
            r = x < two ? UTILS.getRandomIntRange(0,x+50): 3;
            g =0;
            b = 0;
            
            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        
    
            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }
        return array;   
    },
    blueIncremental() {
        let array = [];
        for(var x=0; x<256; x++) {
            let one = UTILS.getRandomIntRange(1, 30)
            // let two = UTILS.getRandomIntRange(3, 6)
            r = 0
            g = 0;
            b = x < 225 ? x+one : 3;
            
            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        
    
            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }
        return array;   
    },
    grayScale() {
        let array = [];
        for(var x=0; x<256; x++) {
            // var i = Math.floor((x+1)/4)
            r = 255-x;
            g = 255-x;
            b = 255-x;
            if(x > 225 ) {
                r = 3;
                g = 3;
                b = 3;
            }
            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        
    
            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }
        return array;   
    },
    grayScaleInverted() {
        let array = [];
        for(var x=0; x<256; x++) {
            // var i = Math.floor((x+1)/4)
            r = x+5;
            g = x+5;
            b = x+5;
            if(x > 250 ) {
                r = 3;
                g = 3;
                b = 3;
            }
            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        
    
            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }
        return array;   
    },
    red() {
        let array = []
        for(let x=0;x<256;x++) // the loop that creates the pallette
        {
            if(x<15) // colors 0-84
            {     
                r=5+x;
                g=0;
                b=0;
            }
            if(x>14 < 30) // colors 0-84
            {       
                r=0;
                g=0;
                b=0;
            }
            if(x>29 < 45) // colors 0-84
            {       
                r=2*x;
                g=0;
                b=0;
            }
            if(x>44 < 60) // colors 0-84
            {       
                r=70+x;
                g=0;
                b=0;
            }
            if(x>59&&x<85) // colors 85-170
            { 
                r=120+x;
                g=x-15;
                b=0;
            }
            if(x>84&&x<125) // colors 85-170
            {
                r=170+x;
                g=0;
                b=0;
            }
            if(x>124&&x<171) // colors 85-170
            {
                r=0;
                g=0;
                b=0;
            }
            if(x>170) // colors 170-255
            {
                r=0;
                g=0;
                b=0;
            }

            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        
    
            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }   
        return array;
    },
    yellow() {
        let array = [];
        // const raise = 15;
        for(let x=0;x<256;x++) // the loop that creates the pallette
        {
            r = x+10;
            g = x;
            b = 0;    
            if(x>225) {
                r=0;
                g=0;
                b=0;
            }
            r=r.toString(16); // conversion to hex
            g=g.toString(16);
            b=b.toString(16);        

            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
        }   
        return array;
    },
    multicolor() {
        let array = [];
        // let i = 255;
        let l = 0
        let set;
        let h = UTILS.getRadomValueRange(0, 1)

        // const raise = 15;
        for(let x=0;x<256;x++) // the loop that creates the pallette
        {
            const s = UTILS.getRandomValue(1)
            
            
            set = UTILS.hslToRgb(h,s,l)
            l = l + 1/255
            if(x == 0 || x >254) {
                set = UTILS.hslToRgb(0,0,0)
            }
            console.log(set);
            r=set[0].toString(16); // conversion to hex
            g=set[1].toString(16);
            b=set[2].toString(16);        

            if (r.length==1) r="0"+r; // add a zero in front to change single-digit to double digit
            if (g.length==1) g="0"+g;
            if (b.length==1) b="0"+b;

            array[x]="#"+r+g+b; // final hex string
            
        }   
        return array;
    }
}

export const PALETTE_ARRAY = () => {
    const array = [];
    Object.values(PALETTE).forEach( (el) => {
        array.push(el)
    })
    return array
}