<template>
  <div class="fractal-wrapper">
    <canvas ref="fractal-canvas"></canvas>
    <slot></slot>
  </div>
</template>

<script>
import { SETTINGS } from '@/constants';
export default {
    name: "FractalCanvas",
    data() {
      return {
        provider: {
          context: null
        }
      }
    },
    methods: {
      down() {
        console.log(this.$slots)
        this.$slots.down()
      },
      download(){
        const canvas = this.$refs['fractal-canvas']
        
        console.log(canvas);
        let newCanvas = document.createElement('canvas', {width: 1800, height: 600})
        newCanvas.width = 1800
        newCanvas.height = 600
        console.log(newCanvas)
        newCanvas.getContext('2d').scale(2,2)
        let _img = document.createElement('img')
        console.log(canvas.toDataURL("image/png"));
        let link = document.createElement('a');
        // Set the link to the image so that when clicked, the image begins downloading
        link.href = _img
        // Specify the image filename
        link.download = 'canvas-download.png';

        _img.src = link.href;//canvas.getContext('2d').getImageData(0,0,900, 300)
        console.log(link.href);
        newCanvas.getContext('2d').drawImage(_img, 300,900, 1800, 600)
        const img = newCanvas.toDataURL("image/png");
        
        const button = this.$refs['download-button']
        button.href= canvas.toDataURL("image/png");
        // Create a dummy link text
        let a = document.createElement('a');
        // Set the link to the image so that when clicked, the image begins downloading
        a.href = img
        // Specify the image filename
        a.download = 'canvas-download.png';
        // Click on the link to set off download
        a.click();
      } 
    },
    provide() {
      return {
        provider: this.provider
      }
    },
    mounted() {
      this.provider.context = this.$refs["fractal-canvas"].getContext("2d");
      this.$refs["fractal-canvas"].width = SETTINGS.width;
      this.$refs["fractal-canvas"].height = SETTINGS.height;
    }

}
</script>

<style>

</style>